import React, {useState} from 'react'
import {Parser} from 'html-to-react'
import {insertTiktok} from '../../lib/helpers'
import Helmet from 'react-helmet'
require('es6-promise').polyfill()
require('isomorphic-fetch')
const parser = new Parser()

export default ({node}) => {
  const {url} = node
  const [video, setVideo] = useState({})

  if (!video.html && url) {
    // eslint-disable-next-line no-undef
    fetch(`https://www.tiktok.com/oembed?url=${url}`)
      .then(response => response.json())
      .then(data => {
        insertTiktok()
        setVideo(data)
      })
      .catch(error => console.log(error.response.data))
  }

  if (!video.html) return <span />

  const html = parser.parse(video.html)
  return (
    <>
      <Helmet>
        <script async src='https://www.tiktok.com/embed.js' />
      </Helmet>
      <div className='max-w-full iframe-container'>{html}</div>
    </>
  )
}
