import React from 'react'
import {Parser} from 'html-to-react'
import {validURL} from '../../lib/helpers'
import Helmet from 'react-helmet'
const parser = new Parser()

export default ({node}) => {
  const {html} = node
  if (!html) return (<span />)

  let scripts = []
  html.split('<script').map(block => {
    block.split('src=').map(src => {
      let url = src.split(/[ >]/)[0]
      url = url ? url.replace(/"/g, '') : ''
      if (validURL(url)) {
        scripts.push(url)
      }
    })
  })

  const parsed = parser.parse(html.replace(/script/g, 'Script') || '')
  return (
    <>
      {scripts.length > 0 &&
        <Helmet>
          {scripts.map(script => <script async src={script} />)}
        </Helmet>
      }
      <div className='flex justify-center max-w-full iframe-container'>
        {parsed}
      </div>
    </>
  )
}
