import React from 'react'

const Footer = () => (
  <footer className='p-8'>
    <div className='container flex flex-col flex-wrap justify-center items-center'>
      <div className='flex justify-center items-center mt-16'>
        <p className='text-center text-gray-700 text-sm font-open-sans'>
          © {new Date().getFullYear()} Tgenz
        </p>
      </div>
    </div>
    {/* <script data-ad-client='ca-pub-8771290932250207' async src='https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js' /> */}
  </footer>
)

export default Footer
