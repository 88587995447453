import React, {useState} from 'react'
import {Link} from 'gatsby'

const Header = () => {
  const [isExpanded, toggleExpansion] = useState(false)

  return (
    <nav className={`sticky bg-white top-0  w-full z-10 top-0 px-12 py-4 shadow-md transition-all duration-200 ease-in-out`}>
      <div className='flex mx-auto container justify-between flex-wrap items-center'>
        <div className='flex items-center flex-shrink-0 text-white mr-6'>
          <Link className='text-purple-600 font-raleway font-black text-3xl no-underline hover:text-purple-800 hover:no-underline' to='/'>
            &#129311; Tgenz
          </Link>
          {/* 11088, 127812, 127825, 127880, 128142, 128293, 129311 */}
        </div>

        <div className='block lg:hidden relative'>
          <button id='nav-toggle' className='flex items-center px-3 py-2 text-gray-500' onClick={() => toggleExpansion(!isExpanded)}>
            <svg className='fill-current h-3 w-3' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'><title>Menu</title><path d='M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z' /></svg>
          </button>
        </div>

        <div className={`w-full flex-grow lg:flex lg:items-center lg:w-auto lg:block pt-6 lg:pt-0 ${isExpanded ? '' : 'hidden'}`}>
          <ul className='list-reset lg:flex justify-end flex-1 items-center'>
            <li className='mr-3 py-4 md:py-0 px-2'>
              <Link className='nav-link font-bold font-open-sans text-gray-700 text-sm hover:text-purple-700' to='/category/tv-and-movies'>TV & Movies</Link>
            </li>
            <li className='mr-3 py-4 md:py-0 px-2'>
              <Link className='nav-link font-bold font-open-sans text-gray-700 text-sm hover:text-purple-700' to='/category/entertainment'>Entertainment</Link>
            </li>
            <li className='mr-3 py-4 md:py-0 px-2'>
              <Link className='nav-link font-bold font-open-sans text-gray-700 text-sm hover:text-purple-700' to='/category/shopping'>Shopping</Link>
            </li>
            <li className='mr-3 py-4 md:py-0 px-2'>
              <Link className='nav-link font-bold font-open-sans text-gray-700 text-sm hover:text-purple-700' to='/category/fashion'>Fashion</Link>
            </li>
            <li className='mr-3 py-4 md:py-0 px-2'>
              <Link className='nav-link font-bold font-open-sans text-gray-700 text-sm hover:text-purple-700' to='/category/music'>Music</Link>
            </li>
            <li className='mr-3 py-4 md:py-0 px-2'>
              <Link className='nav-link font-bold font-open-sans text-gray-700 text-sm hover:text-purple-700' to='/category/tasty'>&#127790; Tasty</Link>
            </li>
            <li className='mr-3 py-4 md:py-0 px-2'>
              <Link className='nav-link font-bold font-open-sans text-gray-700 text-sm hover:text-purple-700' to='/category/lol'>&#128514; LOL</Link>
            </li>
            <li className='mr-3 py-4 md:py-0 px-2'>
              <Link className='nav-link font-bold font-open-sans text-gray-700 text-sm hover:text-purple-700' to='/category/news'>News</Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  )
}

export default Header
