import React from 'react'
import BlockRenderer from './common/Blocks'
import Instagram from './common/instagram'
import Figure from './common/Figure'
import TikTok from './common/tiktok'
import Video from './common/video'
import HTML from './common/html'

const serializers = {
  // container: (<div />),
  types: {
    authorReference: ({node}) => <span>{node.author.name}</span>,
    block: BlockRenderer,
    mainImage: Figure,
    tiktok: TikTok,
    youtube: Video,
    custom_html: HTML,
    instagram: Instagram
  }
}

export default serializers
