import React from 'react'
import PropTypes from 'prop-types'
import Footer from './footer'
import Header from './header'
// import {GoogleReCaptchaProvider, GoogleReCaptcha} from 'react-google-recaptcha-v3'

import '../styles/global.css'

// const RECAPTCHA_KEY = process.env.SITE_RECAPTCHA_KEY

const Layout = ({children}) => {
  return (
    <>
      <Header />
      <main>{children}</main>
      <Footer />
    </>
  )
}
// const Layout = ({children}) => {
//   return (
//     <>
//       <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_KEY}>
//         <Header />
//         <main>{children}</main>
//         <Footer />
//         <GoogleReCaptcha onVerify={token => console.log(token)} />
//       </GoogleReCaptchaProvider>
//     </>
//   )
// }

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout
