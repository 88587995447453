import React from 'react'
import {Link} from 'gatsby'

const CategoryList = ({categories}) => (
  <div className='flex flex-wrap'>
    {categories.map((category, index) => (
      <Link key={index} className='px-2 py-1 rounded text-xxs font-bold uppercase text-white tracking-wider bg-indigo-600 hover:bg-black mr-4' to={`/category/${category.slug}`}>
        <span>{category.title}</span>
      </Link>
    ))}
  </div>
)

export default CategoryList
