import React from 'react'
import {Link} from 'gatsby'
import {format} from 'date-fns'
import {buildImageObj, getBlogUrl} from '../../lib/helpers'
import {imageUrlFor} from '../../lib/image-url'
import OptimizedImage from 'gatsby-image'
import PortableText from '../common/portableText'
import CategoryList from '../category/list'

const BlogPostPreview = ({post}) => {
  post.url = getBlogUrl(post.publishedAt, post.slug.current)
  const author = post.authors.length > 0 ? post.authors[0] : null
  const imageUrl = post.lazyLoaded
    ? imageUrlFor(buildImageObj(post.mainImage)).width(520).auto('format').url()
    : ''

  const authorImageUrl = post.lazyLoaded
    ? imageUrlFor(buildImageObj(author.image)).width(300).auto('format').url()
    : ''

  return (
    <div className='flex flex-col rounded-lg shadow-md border overflow-hidden w-full'>
      <div className='flex-shrink-0'>
        <Link to={post.url}>
          {!post.lazyLoaded && <OptimizedImage className='h-48 w-full object-cover object-center' fadeIn durationFadeIn={1000} fluid={post.mainImage.asset.fluid} alt={post.mainImage.alt} />}
          {post.lazyLoaded && <img className='h-48 w-full object-cover object-center' src={imageUrl} alt={post.mainImage.alt} />}
        </Link>
      </div>
      <div className='flex-1 bg-white p-6 flex flex-col justify-between'>
        <div className='flex-1'>
          <div>
            {
              post.categories.length > 0 &&
              <CategoryList categories={post.categories} />
            }
          </div>

          <h2 className='mt-2 text-2xl leading-7 font-raleway font-bold'>
            <Link className='block text-gray-900 hover:text-purple-600' to={post.url}>{post.title}</Link>
          </h2>

          {post._rawExcerpt && (
            <div className='mt-2 text-sm leading-6 text-gray-700'>
              <PortableText blocks={post._rawExcerpt} />
            </div>
          )}
        </div>
        <div className='mt-3 flex items-center'>
          <div className='leading-5'>
            <Link className='flex hover:underline' to={`/author/${author.slug.current}`}>
              {
                author.image && author.image.asset &&
                <div className=''>
                  {!post.lazyLoaded && <OptimizedImage className='object-cover mr-2 object-center h-5 w-5 rounded-full' fluid={author.image.asset.fluid} alt={author.image.alt} />}
                  {post.lazyLoaded && <img className='object-cover mr-2 object-center h-5 w-5 rounded-full' src={authorImageUrl} alt={author.image.alt} />}
                </div>
              }
              <span className='text-xs text-gray-700 hover:text-gray-900'>{author.name}</span>
            </Link>
          </div>
          <div className='flex ml-1 text-xs leading-5 text-gray-700'>
            <span className='mx-1 text-2xl'>&middot;</span>
            <time dateTime={format(post.publishedAt, 'yyyy-MM-dd')}>{format(post.publishedAt, 'MMMM D, YYYY')}</time>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BlogPostPreview
