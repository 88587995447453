import React from 'react'
import getVideoId from 'get-video-id'

export default ({node}) => {
  const {url} = node
  const {id, service} = getVideoId(url)
  // const aspectRatio = 16 / 9
  // const width = 640
  // const height = width / aspectRatio

  switch (service) {
    case 'youtube':
      return (<div className='relative md:my-4 max-w-full overflow-hidden' style={{paddingTop: '56.25%'}}><iframe className='absolute top-0 left-0 w-full h-full border-0' src={`https://www.youtube.com/embed/${id}?rel=0`} frameBorder='0' allowFullScreen='true' /></div>)
    case 'vimeo':
      return (<div className='relative md:my-4 max-w-full overflow-hidden' style={{paddingTop: '56.25%'}}><iframe className='absolute top-0 left-0 w-full h-full border-0' src={`https://player.vimeo.com/video/${id}`} frameBorder='0' webkitallowfullscreen='true' mozallowfullscreen='true' allowFullScreen='true' /></div>)
    default:
      return (<span>Unsupported video service: {service}</span>)
  }
}
